<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <div class="banner" v-if="banner!=&quot;&quot;&&banner!=null">
      <img :src="banner[0].image" alt="">
      <div class="w1440">
        <div class="txt">
          <p class="fnt_52">{{ banner[0].title }}</p>
          <span class="fnt_18">{{ banner[0].intro }}</span>
        </div>
      </div>
    </div>
    <!-- 第二板块 -->
    <div class="new_list">
      <div class="w1440">
        <!-- 轮播vue -->

        <div class="swiperBox newsListSwiper" v-swiper:mySwiper="swiperOption">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of list" :key="index" v-if="index<3">
              <div class="news_swiper">
                <div class="img_left" ref="simgDiv">
                  <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" :to="'/newsinfo?id='+item.parentId+'&from='+fromQuery"><img :src="item.image" alt="" :style="{height:simgheight+'px'}"></router-link>
                  <router-link v-else target="_blank" :to="'/newsinfo?id='+item.parentId"><img :src="item.image" alt="" :style="{height:simgheight+'px'}"></router-link>
                </div>
                <div class="new_right">
                  <div class="new">
                    <div class="time fnt_20" v-if="item.publicTime!=''&&item.publicTime!=null">{{ item.publicTime }}</div>
                    <div class="time fnt_20" v-else>{{ item.updateTime }}</div>
                    <div class="txt fnt_32" :title="item.title">
                      <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" :to="'/newsinfo?id='+item.parentId+'&from='+fromQuery">{{ item.title }}</router-link>
                      <router-link v-else target="_blank" :to="'/newsinfo?id='+item.parentId">{{ item.title }}</router-link>
                    </div>
                    <div class="norm fnt_18" :title="item.subtitle">{{ item.subtitle }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="sPage" class="swiper-pagination swiper-pagination-bullets sPage"></div>
        </div>
        <!-- 轮播结束 -->

        <!-- 新闻列表 -->
        <div class="p_list">
          <div class="p_loopitem" v-for="(item,index) in list" :key="index" v-if="index>2">
            <div class="e_image" ref="imgDiv">
              <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" :to="'/newsinfo?id='+item.parentId+'&from='+fromQuery">
                <img :src="item.image" alt="" :style="{height:imgheight+'px'}">
                <span class="yan"><img src="@/assets/images/new_yan.png" alt=""></span>
              </router-link>

              <router-link v-else target="_blank" :to="'/newsinfo?id='+item.parentId">
                <img :src="item.image" alt="" :style="{height:imgheight+'px'}">
                <span class="yan"><img src="@/assets/images/new_yan.png" alt=""></span>
              </router-link>
            </div>
            <div class="s_layout">
              <div class="e_text">{{ item.source }}</div>

              <div class="richText fnt_24" :title="item.title">
                <router-link v-if="fromQuery!=''&&fromQuery!=null" target="_blank" :to="'/newsinfo?id='+item.parentId+'&from='+fromQuery">{{ item.title }}</router-link>
                <router-link v-else target="_blank" :to="'/newsinfo?id='+item.parentId">{{ item.title }}</router-link>
              </div>
              <div class="time">
                <img src="@/assets/images/new_time.png" alt="">
                <span class="fnt_16" v-if="item.publicTime!=''&&item.publicTime!=null">{{ item.publicTime }}</span>
                <span class="fnt_16" v-else>{{ item.updateTime }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="p_page">
          <a-pagination v-model="curpage" :total="totalnum" :page-size="pageSize" @change="turnPageFun" :hide-on-single-page="true" show-less-items />&emsp;&emsp;<span>{{ globalDataArray.siteTemplateLanguage.total }} {{ totalnum }} {{ globalDataArray.siteTemplateLanguage.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList } from '@/api/news/index'
import { getAdList } from '@/api/api'
export default {
  name: 'News',
  data() {
    return {
      list: {},
      banner: '',

      curpage: 1,
      pageSize: 18,
      totalnum: 0,
      swiperOption: {
        autoplay: 15000,
        // loop: true,
        pagination: '.sPage',
        paginationClickable: true
      },
      imgwidhth: 0,
      simgwidhth: 0,

      fromQuery: ''
    }
  },
  created() {

  },
  computed: {
    imgheight() {
      return Math.floor(this.imgwidhth * 0.56)
    },
    simgheight() {
      return Math.floor(this.simgwidhth * 0.56)
    }
  },
  mounted() {
    this.init()

    if (typeof window !== 'undefined') {
      window.onresize = () => {
        this.$nextTick(() => {
          if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
            this.imgwidhth = this.$refs.imgDiv[0].offsetWidth
          }

          if (this.$refs.simgDiv && this.$refs.simgDiv.length > 0) {
            this.simgwidhth = this.$refs.simgDiv[0].offsetWidth
          }
        })
      }
    }

    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }

    if (this.$route.query.from) {
      this.fromQuery = this.$route.query.from
    }
  },
  methods: {
    init() {
      const that = this

      const datas = 'alias=news_banner'
      getAdList(datas).then(res => {
        if (res.success) {
          that.banner = res.result
          // console.log(res)
        }
      })

      that.getNewsListFun()
    },

    getNewsListFun() {
      const that = this
      const param = 'p=' + that.curpage + '&pageSize=' + that.pageSize
      getNewsList(param).then(res => {
        if (res.success && res.result != '' && Object.keys(res.result).length > 0) {
          that.totalnum = res.result.pageList.total
          that.list = res.result.pageList.records

          setTimeout(() => {
            if (typeof document === 'object' && that.list != null && that.list != '') {
              document.getElementById('sPage').childNodes[0].classList.add('swiper-pagination-bullet-active')
            }
          })

          that.$nextTick(() => {
            if (this.$refs.imgDiv && this.$refs.imgDiv.length > 0) {
              that.imgwidhth = that.$refs.imgDiv[0].offsetWidth
            }
            if (this.$refs.simgDiv && this.$refs.simgDiv.length > 0) {
              that.simgwidhth = that.$refs.simgDiv[0].offsetWidth
            }
          })
        }
      })
    },

    turnPageFun(e) {
      // 回调翻页页码
      // console.log(e)
      this.curpage = e
      this.getNewsListFun()
    }
  },
  metaInfo() {
    let title = ''
    let description = ''
    let keywords = ''
    if (this.globalDataArray.siteNavigation[2].title != '' && this.globalDataArray.siteNavigation[2].title != null && this.globalDataArray.siteNavigation[2].title != ' ') {
      title = this.globalDataArray.siteNavigation[2].title + '-' + this.globalDataArray.siteInfo.title
    } else {
      title = this.globalDataArray.siteInfo.title
    }
    if (this.globalDataArray.siteNavigation[2].description != '' && this.globalDataArray.siteNavigation[2].description != null) {
      description = this.globalDataArray.siteNavigation[2].description
    } else {
      description = this.globalDataArray.siteInfo.description
    }
    if (this.globalDataArray.siteNavigation[2].keywords != '' && this.globalDataArray.siteNavigation[2].keywords != null) {
      keywords = this.globalDataArray.siteNavigation[2].keywords
    } else {
      keywords = this.globalDataArray.siteInfo.keywords
    }
    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/news.css'
</style>
